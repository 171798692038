<template>
  <div id="play"></div>
</template>
<script>
// import luzo from "../util/luzo.json";
export default {
  data() {
    return {
      count: 0,
    };
  },

  mounted() {
    // this.recordFun.operationServe();
    // this.play();
  },
  methods: {
    play() {
      // let that = this;
      // console.log(luzo)
      // that.recordFun.operationReplayer("play",luzo);
    },
  },
};
</script>
<style lang="less" scoped>
#play {
  width: 100%;
  height: 100%;
}
</style>